/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import {StaticQuery, graphql} from "gatsby"
import {Container, Row, Col} from "react-bootstrap"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookF, faInstagram} from '@fortawesome/free-brands-svg-icons'

import heroImage from '../images/hero-image.jpg'
import Navbar from "./navBar"
import logoImage from "../images/ScratchpatchtextLogo.png";

const HeroStyle = {
    height: "43rem",
    backgroundImage: `url(${heroImage})`
}

const Layout = ({children, pageInfo}) => (

    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
            <>
                <header className="py-5 bg-image-full" style={HeroStyle}>
                    <Container fluid className="main">
                        <Navbar pageInfo={pageInfo}/>
                        <Row>
                            <div className="my-auto logo-container">
                                <img alt="logo" className="logo-img" src={logoImage}/>
                            </div>
                        </Row>
                    </Container>
                </header>
                <Container fluid>
                    <Row noGutters>
                        <Col>
                            <div>
                                <main>{children}</main>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <footer>
                    <Container fluid className="px-0">
                        <Row noGutters>
                            <Col lg={6} className='footer-col'>
                                <div className='content'>
                                <span>
                                    <a className='pb-3 link' href='https://goo.gl/maps/t81JjGSQZNUBqgiW9'>1 Old Fort Rd,
                                    Salt
                                    Rock, Durban, 4391</a>
                                    <div className='pt-3'>
                                        <a href="mailto:info@sagescratchpatch.co.za?subject=Enquire"
                                           className="btn btn-outline-white py-3 px-5">Contact Us</a>
                                    </div>
                                </span>
                                </div>
                            </Col>
                            <Col lg={6} className="footer-col">
                                <div className='content'>
                                <span>
                                    <div>
                                        <a href='https://www.instagram.com/sagescratchpatch/' className='px-2'
                                           target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faInstagram} size="3x" style={{color: '#B25F89'}}/>
                                        </a>
                                        <a href='https://www.facebook.com/Sage-Scratch-Patch-115339336531673/'
                                           className='px-2' target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faFacebookF} size="3x" style={{color: '#B25F89'}}/>
                                        </a>
                                    </div>
                                    <div className='pt-3'>
                                         © {new Date().getFullYear()}, Sage Scratch Patch
                                        {` `}
                                    </div>
                                </span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </>
    )}
  />
)

export default Layout
