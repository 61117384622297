import React from "react"
import {Link} from "gatsby"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {faBars} from '@fortawesome/free-solid-svg-icons'
import {StaticQuery, graphql} from "gatsby";
import {Col, Container, Row, Nav} from "react-bootstrap";

library.add(
    faBars
)

const CustomNavbar = ({pageInfo}) => (
    <StaticQuery
        query={graphql`
      query TitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
            <>
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col className="dropdown-container">
                            <div className="float-right">
                                <div className="dropdown">
                                    <FontAwesomeIcon icon={faBars} size="2x" style={{color: 'white'}}/>
                                    <div className="dropdown-content">
                                        <Link to="/" className="link-no-style">
                                            <Nav.Link as="span" eventKey="page-2">
                                                Home
                                            </Nav.Link>
                                        </Link>
                                        <Link to="/#info" className="link-no-style">
                                            <Nav.Link as="span" eventKey="index">
                                                Info
                                            </Nav.Link>
                                        </Link>
                                        <Link to="/#gallery" className="link-no-style">
                                            <Nav.Link as="span" eventKey="index">
                                                Gallery
                                            </Nav.Link>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        )}
    />
)

export default CustomNavbar
